// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wgjIjymAZ"];

const serializationHash = "framer-6ide1"

const variantClassNames = {wgjIjymAZ: "framer-v-1jazwte"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, AfdHcWyNs: link ?? props.AfdHcWyNs, jB4Lzeyku: image ?? props.jB4Lzeyku ?? {src: "https://framerusercontent.com/images/g2lmsJkczGHJTiBF4KyNVAsAB7Y.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jB4Lzeyku, AfdHcWyNs, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "wgjIjymAZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={AfdHcWyNs}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 24, intrinsicWidth: 134, pixelHeight: 24, pixelWidth: 134, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(jB4Lzeyku), ...{ positionX: "center", positionY: "center" }}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1jazwte", className, classNames)} framer-1wxg2ni`} data-framer-name={"Primary"} layoutDependency={layoutDependency} layoutId={"wgjIjymAZ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6ide1.framer-1wxg2ni, .framer-6ide1 .framer-1wxg2ni { display: block; }", ".framer-6ide1.framer-1jazwte { height: 56px; overflow: visible; position: relative; text-decoration: none; width: 205px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 205
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"jB4Lzeyku":"image","AfdHcWyNs":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqXfVG6fSe: React.ComponentType<Props> = withCSS(Component, css, "framer-6ide1") as typeof Component;
export default FramerqXfVG6fSe;

FramerqXfVG6fSe.displayName = "Logo";

FramerqXfVG6fSe.defaultProps = {height: 56, width: 205};

addPropertyControls(FramerqXfVG6fSe, {jB4Lzeyku: {__defaultAssetReference: "data:framer/asset-reference,g2lmsJkczGHJTiBF4KyNVAsAB7Y.svg?originalFilename=Original+Logo.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, AfdHcWyNs: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerqXfVG6fSe, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})